import React from "react";
import LangSelect from "./components/langSelect/LangSelect";
import BurgerMenu from "./components/BurgerMenu";
import Navbar from "./components/navbar/Navbar";
import { Logo } from "src/components";
import { languages } from "src/assets";
import { useMediaContext } from "src/utilities/mediaQuery";

import "./header.css";

const Header = ({ linkList, languageSelect }) => {
  const screenMode = useMediaContext();

  return (
    <header className="header">
      {screenMode === "mobile" && <BurgerMenu linkList={linkList} />}
      {screenMode === "desktop" && <Navbar linkList={linkList} />}
      <Logo />
      <LangSelect languages={languages} languageSelect={languageSelect} />
    </header>
  );
};

export default Header;
