export const contentSerbian = {
  navigation: [
    {
      type: "submenu",
      title: "rešenja",
      link: "solutions",
      subMenu: [
        {
          type: "link",
          title: "Radiolog kao usluga - RaaS",
          link: "raas",
        },
        {
          type: "link",
          title: "ExaWorkS - SaaS",
          link: "exaworks",
        },
        { type: "link", title: "TelMed PACS rešenja ", link: "pacs" },
        { type: "link", title: "System integracija", link: "integration" },
      ],
    },
    { type: "link", title: "O nama", link: "workUs" },
    {
      type: "submenu",
      title: "Radite sa nama",
      link: "about",
      subMenu: [
        { type: "link", title: "Budite naš konsultant", link: "consult" },
        { type: "link", title: "Postanite naš partner", link: "partner" },
      ],
    },
    { type: "link", title: "Najčešća pitanja", link: "faq" },
    { type: "link", title: "Kontakt", link: "contact" },
    // { type: "link", title: "Vesti", link: "news" },
  ],
  buttons: {
    readMore: "opširnije",
  },
  opener: [
    {
      type: "article",
      header: "Evropski radiološki stručnjaci za tržišta u razvoju",
      content: [
        "Inovativno rešenje za tržišta u razvoju. Donosimo iskustvo evropskih lekara, nudimo integralna rešenja za podršku zdravstvenim radnicima.",
        "Zahvaljujući našim uslugama, odeljenja radiologije u bolnicama dobijaju brz pristup radiološko konsultativnim kapacitetima kojima njihove ustanove ne raspolažu.",
      ],
    },
    {
      type: "article",
      header: "Radiologija dostupna svima",
      content: [
        "Verujem da nas teleradiologija uvodi u novo doba digitalne medicine koje zauzima sve veće mesto u svetu.",
        "Lično se radujem budućoj saradnji sa radiolozima i partnerima širom sveta, kao i uvođenju evropskih standarda u radiologiju na tržištima u razvoju .",
        "Ivan Jurčić, CEO",
      ],
    },
  ],
  raas: [
    { type: "header", content: "Usluge" },
    {
      type: "section",
      content: [
        {
          type: "article",
          header: "Redovne teleradiološke konsultacije ",
          content: [
            "TelMed Vam obezbeđuje konsultativnu podršku na daljinu, kada izveštaj nije potreban u određenom vremenskom roku.  U mogućnosti smo da Vam ponudimo podršku vezanu za dijagnostičke procedure, kao što je konvencionalna radiografija (CR), kompjuterizovana tomografija (CT), i magnetna rezonancija (MR).",
            "Iza naših fleksibilnih i pouzdanih usluga stoji veliki broj radiologa iz cele Evrope. ",
          ],
        },
        {
          type: "article",
          header: "Urgetne teleradiološke konsultacije",
          content: [
            "TelMed Vam nudi dnevne (08-20h CET) i noćne (20-08h CET)  radiološke usluge vezane za urgentna stanja, koje su Vam na raspolaganju 24 sata, 7 dana u nedelji, bez obzira na vremensku zonu u kojoj se nalazite.  Na raspolaganju su Vam teleradiološka rešenja za Vaše interne potrebe koja rasterećuju Vaše osoblje i predstavljaju sredstvo za poboljšanje radiološke prakse.",
            "Naše usluge urgentnog konsaltinga Vam obezbeđuju direktan pristup konsultantima radiologije širom Evrope sa velikim iskustvom u urgentnoj medicini.",
            "Za sve Vaše zahteve vezane za radiološke konsultacije van redovnog radnog vremena, na raspolaganju su Vam naši administratori i dežurna podrška koja Vam je dostupna 24 sata 7 dana u nedelji.",
          ],
        },
        {
          type: "article",
          header: "Izveštaj specijaliste ",
          content: [
            "Izveštaj specijaliste radiologa je suštinski deo komunikacije između radiologa i lekara drugih specijalnosti koji upućuju pacijenta na pregled.",
            "Preko TelMed platforme dobijate konačan izveštaj naših iskusnih  dijagnostičara.",
          ],
        },
      ],
    },
    {
      type: "section",
      content: [
        {
          type: "article",
          header: "Snimanje glave, vrata i kičme",
          content: [
            "Naša usluga obuhvata detaljno i precizno tumačenje i izradu izveštaja o uobičajenim i manje uobičajenim patološkim stanjima glave, vrata i kičme. Trauma, inflamatorna/infektivna etiologija, cerebrovaskularni ispadi, neoplazme i metastatski procesi, bolesti metabolizma i česte protruzije spinalnih diskova su najčešći razlozi za radiološka snimanja, kao što su CT skener i magnetna rezonancija. Za procenu koštanih struktura glave i kičmenog stuba u situacijama kada CT i MR dijagnostika nisu dostupne, postoji česta potreba za osnovnom konvencionalnom radiografijom (CR).",
          ],
        },
        {
          type: "article",
          header: "Snimanje grudnog koša",
          content: [
            "Kao i u drugim sistemima, brojna patološka stanja mogu imati uticaj na pluća i srce. Inflamatorne i infektivne bolesti, razne vrste tumora, kongenitalne i autoimune bolesti i traumatski poremećaji su česte indikacije za CR i CT ispitivanja grudnog koša.",
            "Iskusni TelMed radiolozi su sposobni za procenu i najkomplikovanijih patoloških stanja regije grudnog koša.",
          ],
        },
        {
          type: "article",
          header: "Vaskularna radiološka dijagnostika",
          content: [
            "Procena vaskularnog sistema, koji predstavlja veoma složenu mrežu krvnih sudova, se može vršiti pomoću CT/MR angiografije (arteriografija i flebografija). Urođene anomalije kao što su aneurizme i arteriovenske malformacije, druge urođene vaskularne aberacije, vaskularna stenoza  i preoperativno planiranje su najvažnije indikacije koje se procenjuju putem CT i MR snimanja.",
            "TelMed radiolozi su tu da Vam pruže precizno tumačenje dobijenih CT/MR tomograma.",
          ],
        },
        {
          type: "article",
          header: "Mamografija i MR snimanje dojki",
          content: [
            "Mamografija je način snimanja koji se koristi u situacijama kao što su: slučajevi kada ultrazvuk nije dovoljno pouzdana dijagnostička metoda, prethodno otkrivene nepravilnosti u građi parenhima dojke, prisustvo tumorskih lezija, sumnjive kalcifikacije u dojkama, palpabilna rezistencija u dojci koja se ne registruje pri ultrazvučnom pregledu, rano otkrivanje karcinoma dojke kod žena sa visokim rizikom i  u sklopu preoperativne pripreme već verifikovanih tumorskih lezija u dojci.",
            "Najčešći razlozi za magnetnu rezonanciju dojke su: Procena abnormalnosti uočenih na mamografskom snimku (tumačenje tačne lokacije, veličine i eventualno prisustvo multifokalnih lezija na dojci zajedno sa aksilarnim regijama; detekcija širenja tumorskog procesa ka zidu grudnog koša što može uticati na promenu pristupa u lečenju, itd.); nakon augmentacije dojke kada ožiljno tkivo može da  imitira maligne lezije  na mamogramu kao i otkrivanje rupture implanta.",
            "Sa TelMed platformom i uz pomoć naših specijalista radiologije, imate mogućnost da dobijete stručno medicinsko mišljenje i izveštaje visokog kvaliteta.",
          ],
        },
        {
          type: "article",
          header: "Snimanje abdomena i male karlice",
          content: [
            "Patološka stanja abdomena i male karlice su jedni od najčešćih razloga za primenu CT i MR dijagnostičkih metoda. Nažalost, danas je prisutna globalna ekspanzija malignih bolesti, zbog koje postoji velika potreba za primenom CT i MR dijagnostike. Neoplazme debelog creva, hepatobilijarnog trakta, bubrega i bešike, kao i ginekološki maligniteti i maligniteti prostate, metastaze na jetri i limfadenopatija  abdomena i male karlice, se vrlo često sreću u patološkim nalazima. Precizna detekcija lokacije primarnog tumora i  mogućih lokoregionalnih i udaljenih metastaza sa preciznim definisanjem stadijuma bolesti su  podaci neophodni za planiranje daljih medicinskih procedura.",
            "TelMed eksperti nude  stručna medicinska mišljenja u oblasti patologije abdomena i male karlice.",
          ],
        },
        {
          type: "article",
          header: "Konsultacije u oblasti muskuloskeletnog sistema",
          content: [
            "U današnje vreme možemo otkriti brojna muskuloskeletna patološka stanja zahvaljujući dijagnostičkim metodama kao što su CR, CT i MR.  CT i MR metode igraju veliku ulogu u evaluaciji tumora kostiju. Sa tipičnim izgledom nekih patoloških promena, CT ili MR sa velikom specifičnošću i senzitivnošću  daju preliminarnu dijagnozu (na primer tumora kostiju kao što su enhondrom, neosifikujući fibrom (NOF), osteosarkom, Juingov sarkom,itd). Pored toga, mnoge inflamatorne, degenerativne i metaboličke bolesti pogađaju skeletni sistem  i mogu zahtevati dalju CT ili MR dijagnostiku.",
            "Od naših radiologa možete dobiti stručno mišljenje u formi preliminarnih ili finalnih izveštaja.",
          ],
        },
        {
          type: "article",
          header: "Pedijatrija",
          content: [
            "Radiolog prvo mora da razmišlja o koristima i rizicima izlaganja pacijenta jonizujućem zračenju  koje se emituje prilikom CR i CT snimanja. Nažalost, kod dece sa posebnom kliničkom slikom i ozbiljnijom patologijom CR i CT dijagnostičko snimanje je neophodno. MR je vrsta snimanja bez jonizujućeg zračenja tako da je bezbedno  (osim u slučaju postojećih kontraindikacija) za primenu kod dece.",
            "TelMed teleradiološka platforma obezbeđuje konsultativni sistem visokog kvaliteta u oblasti pedijatrije, nakon prethodne CR, CT, i MR dijagnostike.",
          ],
        },
      ],
    },
  ],
  exaworks: [
    { type: "header", content: "TelMed ExaWorkS (SaaS)" },
    {
      type: "section",
      content: [
        {
          type: "article",
          header: "Predstavljamo ExaWorkS",
          content: [
            "Kompletno SaaS rešenje za upravljanje procesom radiološkog istraživanja u okviru zdravstvenoh institucija.",
            "ExaWorkS je podržan prilagodljivom PACS osnovom,",
            "sa kojom formira sveobuhvatnu platformu koja pojednostvljuje kompletan radiološki proces, od preuzimanja slika, do izrade izveštaja.",
          ],
        },
      ],
    },
    {
      type: "section",
      content: [
        {
          type: "article",
          header: "Custom Integration",
          content: [
            "Prilagođena integracija",
            "ExaWorkS je pordžan moćnim PACS rešenjem koje pruža bezbedan, skalabilan i efikasan sistem za pohranjivanje i preuzimanje radioloških studija.",
            "Ova osnova garantuje dostupnost i sigurno čuvanje medicinskih slika i podataka.",
          ],
        },
        {
          type: "article",
          header: "Napredan tiketing sistem",
          content: [
            "Naša platforma uključuje intuitivni tiketing sistem koji omogućava kliničkim administratorima da dodeljuju studije radiolozima prema njihovmo profilu (specijalizscija, subspecijalizacija).",
            "Ovo osigurava da je svaki slučaj dodeljen nejkompetentnijim stručnjacima, što ubrzava kvalitet i brzinu pregleda.",
          ],
        },
        {
          type: "article",
          header: "Automatizovana dodela studija",
          content: [
            "ExaWorkS automatizovana dodela studija koristi pametne algoritme kako bi distribucija slučajeva među radilozima bila optimizovana i osigurano pravovremeno obavljanje istraživanja i izrada izveštaja.",
          ],
        },
        {
          type: "article",
          header: "Integrisani veb pregledač, 'TelMed DICOM Viewer'",
          content: [
            "Radiolozi pristupaju i anliziraju studije uz pomoć integrisanog TelMed DICOM pregledača, moderne, veb-bazirane aplikacije opremljene naprednim setom alata za pregled i obradu.",
            "Koristi se bez instalacije, što ga čini dostupnim sa bilo koje lokacije uz pomoć internet pregledača",
          ],
        },
        {
          type: "article",
          header: "Efikasno pisanje izveštaja uz pomoć šablona",
          content: [
            "ExaWorkS Vam stavlja na raspolaganje bogatu kolekciju šablona radiloških izveštaja koji pojednostavljuju proces pisanja. Izveštaji se mogu pisati direkno u TelMed DICOM pregledaču uz korišćenje standardnih šablona koji obezbeđuju njihovu temeljnost i uniformnost.",
          ],
        },
        {
          type: "article",
          header: "'Zero-Footprint' pristup",
          content: [
            "Naš 'zero-footprint' pregledač osigurava da se podaci o pacijentu ne zadržavaju u lokalnim računarima, u skladu sa pravilima o zaštiti podataka.",
            "radilozi mogu raditi bez brige o kompromitovanju pivatnosti podataka o pacijentu.",
          ],
        },
        {
          type: "article",
          header: "",
          content: [
            "Rad na više lokacija",
            "ExaWorkS je razvijen sa podrškom za rad na više lokacija u okviru iste zdravstvene ustanove, obezbeđujući objedinjenu platformu za upravljanje radiološkim procedurama na različitim mestima.",
          ],
        },
      ],
    },
  ],
  pacs: [
    { type: "header", content: "TelMed PACS rešenja" },
    {
      type: "section",
      content: [
        {
          type: "article",
          header: "TelMed PACS ",
          content: [
            "Nova usavršena rešenja za pohranjivanje i menadžment medicinskih podataka korišćenjem modernih tehnologija.",
            "Ovaj pristup nudi niz prednosti u odnosu na tradicionalne lokalne PACS sisteme:",
          ],
        },
      ],
    },
    {
      type: "section",
      content: [
        {
          type: "article",
          header: "Čuvanje i servisi",
          content: [
            "Čuvanje i povlačenje: TelMed PACS reguliše čuvanje i povlačenje radioloških slika i kliničke dokumentacije lokalno, ili u klaudu, prema potrebama klijenta.",
            "Održavanje: Obezbeđujemo kontinuirano održavanje, sistem je uvek ažuran i funkcionalan.",
          ],
        },
        {
          type: "article",
          header: "Pohranjivanje podataka",
          content: [
            "DICOM slike: Možete slati različite modalitete DICOM slika kao što su CT, MRI, X-ray, itd.",
            "Osim DICOM formata: Moguće je čuvati JPEG, BMP, PNG, MP4, PDF, itd., čineći platformu prilagđenom za čuvanje najrazličitijih tipova podataka.",
          ],
        },
        {
          type: "article",
          header: "Funkcionalnost",
          content: [
            "Čuvanje: Čuvajte sve slike i dokumentaciju u obezbeđenoj, centralizovanoj bazi.",
            "Pristup: Pristupajte i pregledajte slike pomoću TelMed PACS interfejsa.",
            "Izveštaji: Izrađujte detaljne izveštaje koji mogu biti podeljeni sa kolegama i vašim pacijentima.",
            "Kooperacija: deljenje dokumentacije i slika sa kolegama i pacijentima je obezbeđeno pomoću sigurnih linkova.",
          ],
        },
        {
          type: "article",
          header: "'Disaster PACS' čuvanje i spasavanje podataka",
          content: [
            "Sigurnost podataka: 'Disaster PACS' sprečava gubitak i krađu podataka uz pomoć servisa za čuvanje i spasavanje.",
            "Spasavanje podataka: U slučaju nepredviđenih okolnosti, Vaši podaci se mogu brzo i lako vratiti, čime Vam je obezbeđen kontinuitet u radu.",
          ],
        },
        {
          type: "article",
          header: "Deljenje slika",
          content: [
            "Nema potrebe DICOM CD-ovima",
            "- Oslobodite se potrebe da narezujete i nosite DICOM CD-ove.",
            "- Koristite TelMed PACS da sa lakoćom delite slike i kliničku dokumentaciju preko klauda, ili svojih lokalnih servera.",
            "Pravite sigurne linkove za deljenje",
            "- Sigurno deljenje: Napravite link za deljenje sa zaštitnom lozinkom kako bi slike i dokumente učinili dostupnim svojim saradnicima i pacijentima.",
            "- Momentalan pristup: Saradnici mogu pristupiti podeljenom sadržaju čim dobiju link i lozinku.",
            "Jednostavno korisničko okruženje",
            "- Za pacijente i doktore: Pacijenti i doktori mogu pristupati podeljenim dokumentima jednostavno i bez posebnog softvera.",
            "- Lakoća upotrebe: Proces deljenja je pojednostavljen, što ga čino brzim i efikasnim.",
          ],
        },
        // {
        //   type: "article",
        //   header: "Creating Share Links",
        //   content: [
        //     "- Secure Sharing: Create a share link with an access password for the secure sharing of images and documents.",
        //     "- Secure Sharing: Create a share link with an access password for the secure sharing of images and documents.",
        //     "Recovery: In the event of a disaster, your data can be quickly and reliably recovered, ensuring continuity of care.",
        //   ],
        // },
      ],
    },
  ],
  systemIntegration: [
    { type: "header", content: "Integracija sistema" },
    {
      type: "section",
      content: [
        {
          type: "article",
          header: "Nudimo poslovna rešenja",
          content: [
            "Integracija sistema se može izvršiti na različite načine i na različitim nivoima.",
            "Zahvaljujući našem bogatom iskustvu, možemo da izađemo u susret svim zahtevima klijenata izvođenjem projekta prema sledećem planu:",
          ],
        },
        {
          type: "article",
          header: "Analiza",
          content: [
            "Prikupljanje zahteva: Razumevanje potreba klijenta i postojeće infrastrukture.",
            "Procena izvodljivosti: Procena praktičnosti i potencijalnog uticaja integracije.",
            "Analiza mogućnosti: Identifikovanje bilo kakvog jaza između trenutnih sposobnosti i željenih rezultata.",
          ],
        },
      ],
    },
    {
      type: "section",
      content: [
        {
          type: "article",
          header: "Planiranje, dizajn i razvoj",
          content: [
            "Planiranje projekta: Definisanje obima projekta, vremenskih rokova i alokacije resursa.",
            "Dizajn sistema: Kreiranje detaljnih projekata i planova integracije.",
            "Razvoj: Prilagođavanje i razvoj komponenti kako bi se obezbedila besprekorna integracija.",
          ],
        },
      ],
    },
    {
      type: "section",
      content: [
        {
          type: "article",
          header: "Implementacija",
          content: [
            "Instalacija: Postavljanje TelMed rešenja unutar klijentskog okruženja.",
            "Integracija: Obezbeđivanje da sve komponente rade u skladu sa postojećim sistemima.",
            "Migracija podataka: Bezbedan prenos podataka sa starih sistema u novo okruženje.",
          ],
        },
      ],
    },
    {
      type: "section",
      content: [
        {
          type: "article",
          header: "Održavanje i podrška",
          content: [
            "Tehnička podrška: Podrška je uvek dostupna za sve probleme koje klijent prijavi.",
            "Rešavanje problema: Brzo rešavanje bilo kakvih tehničkih problema.",
            "Nadgledanje i održavanje: Redovno nadgledanje i održavanje hardverskih i softverskih komponenti kako bi bile osigurane optimalne performanse.",
            "Naša uloga sistemskog integratora obuhvata ove usluge kako bismo garantovali efikasan rad medicinskih dijagnostičkih sistema.",
            "Pored pružanja tehničke podrške kada se pojave problemi, mi redovno pratimo i održavamo hardverske i softverske komponente medicinskog dijagnostičkog sistema za naše partnere. Ovaj proaktivni pristup pomaže u sprečavanju potencijalnih problema i osigurava dugovečnost i pouzdanost sistema.",
          ],
        },
      ],
    },
  ],
  aboutUs: [
    { type: "header", content: "O nama" },
    {
      type: "article",
      header: "Teleradiologija za tržišta u razvoju",
      content: [
        "TelMed želi da preuzme vodeću ulogu u oblasti konsultacija iz teleradiologije na tržištima u razvoju nakon uspostavljanja svojih integralnih rešenja. Naš cilj je da učinimo da  evropski stručnjaci iz oblasti radiologije budu dostupni zdravstvenim ustanovama koje ne raspolažu dovoljnim stručnim resursima.",
        "Skloni smo da verujemo da je nova tehnologija namenjena razvijenom svetu. Šta ako je u ovom slučaju sudbina tehnologije da promeni način na koji se medicina shvata i primenjuje u Centralnoj i Istočnoj Evropi, udaljenim delovima Afrike, Azije, Bliskog Istoka i Južne Amerike.",
        "TelMed želi da vodi tu promenu!",
      ],
    },
    {
      type: "accordion",
      title: "naši ciljevi",
      content: [
        {
          title: "KVALITET",
          content:
            "Teleradiološka platforma obezbeđuje pristup vodećim radiolozima iz Evrope i pruža dijagnostičku tačnost smanjujući potrebno vreme za odgovor medicinskog osoblja u dijagnostici kroz TelMed platformu uz pomoć veštačke inteligencije  i operativnih modela.",
        },
        {
          title: "FLEKSIBILNOST",
          content:
            "Želimo da zadržimo najviše standarde u radiologiji, sa maksimalnom fleksibilnošću, kako za radiologe tako i za zdravstvene ustanove, po pristupačnim cenama, i da Vam pružimo uslugu bez obzira na to gde se nalazite.",
        },
        {
          title: "INOVACIJA",
          content:
            "TelMed automatska prevodilačka usluga će osigurati tačan prevod dijagnostičkih izveštaja koji su prilagođeni Vašem tržištu i lokalnom jeziku.",
        },
        {
          title: "OTVORENOST",
          content:
            "Platforma obezbeđuje jednostavan način za deljenje i komentarisanje snimaka pacijenata  između radioloških eksperata i kolega iz drugih medicinskih disciplina radi postizanja što tačnije i brže dijagnoze koja  doprinosi  boljem postterapijskom ishodu.",
        },
      ],
    },
    {
      type: "article",
      header: "Radiologija dostupna svima",
      content: [
        "Verujem da nas teleradiologija uvodi u novo doba digitalne medicine koje zauzima sve veće mesto u svetu. Lično se radujem budućoj saradnji sa radiolozima i partnerima širom sveta, kao i uvođenju evropskih standarda u radiologiju na tržištima u razvoju .",
        "Ivan Jurčić, CEO",
      ],
    },
  ],
  features: [
    { type: "header", content: "šta telmed platforma nudi" },
    {
      type: "section",
      content: [
        {
          type: "article",
          header: "brze i tačne konsultacije",
          content: [
            "TelMed teleradiološka platforma daje Vam pristup radiolozima u Evropi, i time skraćuje vreme potrebno za odgovor medicinskog osoblja u dijagnostici.",
          ],
        },
        {
          type: "article",
          header: "sveobuhvatna usluga",
          content: [
            "Obezbeđujemo pristup radiolozima, softveru, IT infrastrukturi, prevod izveštaja i tehničku podršku. Sa nama dobijate sve što Vam je potrebno za nadgradnju svojih kapaciteta u oblasti dijagnostike.",
          ],
        },
        {
          type: "article",
          header: "jezička lokalizacija izveštaja",
          content: [
            "Jezik koji razumete. TelMed usluga automatskog prevoda će osigurati tačan prevod dijagnostičkih izveštaja koji su prilagođeni Vašem tržištu i lokalnom jeziku.",
          ],
        },
        {
          type: "article",
          header: "napredna tehnološka infrastruktura ",
          content: [
            "TelMed softver šalje snimke u roku od nekoliko minuta i kreira izveštaje za brže i efikasnije dijagnostikovanje, čak i u najudaljenijim delovima sveta. Naša IT infrastruktura je fleksibilna i optimizovana tako da možemo da se povežemo sa Vašim RIS/PACS bez obzira gde se nalazite, sa posebnom pažnjom vezanom za bezbednost podataka i privatnost pacijenata. ",
          ],
        },
        {
          type: "article",
          header: "ekonomična usluga",
          content: [
            "Uštedite novac i resurse sa TelMed platformom. Naša platforma nudi ekonomičnost kroz široku mrežu evropskih radiologa.",
          ],
        },
        {
          type: "article",
          header: "partnerstvo",
          content: [
            "Želimo da razumemo Vaše potrebe i da ispunimo sve Vaše zahteve. Ako ste zdravstvena ustanova ili distributer medicinske opreme, mi smo Vaš pouzdani partner!",
          ],
        },
      ],
    },
  ],
  faq: [
    { type: "header", content: "često postavljana pitanja" },
    {
      type: "accordion",
      content: [
        {
          title: "Da li se svi radiolozi koji rade za TelMed nalaze u Evropi?",
          content:
            "Da, svi naši radiolozi se nalaze u Evropi. TelMed radiolozi imaju veliko iskustvo u radiološkoj praksi i radu po evropskim standardima.",
        },
        {
          title:
            "Da li ćemo imati troškove za povezivanje ili za tekuću administraciju i održavanje?",
          content:
            "Za osnovnu TelMed konekciju nema troškova. Sve što nam je od Vas potrebno je minimalno učestvovanje Vašeg IT odeljenja u uspostavljanju konekcije.",
        },
        {
          title: "Koliko vremena je potrebno za podešavanje?",
          content:
            "Uz Vašu podršku, imaćete brzu integraciju uz pomoć brojnih rešenja koja su prilagođena tehnološkoj infrastrukturi klinike. Nakon relativno jednostavne instalacije koju će voditi naši tehničari, Vaša konekcija će biti aktivna i bićete spremni za upotrebu sistema. VPN ili slična konfiguracija nije potrebna – kompletna komunikacija se vrši preko HTTPS protokola.",
        },
        {
          title: "Kako se povezujemo?",
          content:
            "Jednostavno popunite obrazac ugovora i sačekajte da Vas kontaktiramo.",
        },
        {
          title:
            "Da li će radiolozi na daljinu imati pristup svim klinički relevantnim informacijama?",
          content:
            "TelMed radiolozi imaju pristup svim klinički relevantnim informacijama. Svi izveštaji su sačinjeni uz korišćenje dijagnoza i izveštaja pacijenata i relevantnih dijagnostičkih snimaka. ",
        },
        {
          title: "Da li postoje procedure za praćenje realizacije ugovora?",
          content:
            "Naš softver omogućava konstantno praćenje ugovornih ciljeva, a menadžeri za ključne kupce redovno prate taj proces.  Naš tim će Vam biti na raspolaganju za sva pitanja koja budete imali. ",
        },
        {
          title: "Da li pružate usluge subspecijalističkih konsultacija?",
          content:
            "Da, TelMed omogućava razne vrste konsultacija iz radioloških subspecijalističkih disciplina. Ako je potrebno, naši radiolozi su dostupni za sastanke sa timovima iz raznih multidisciplinarnih oblasti.",
        },
        {
          title: "Koliko je vremena potrebno za obradu zahteva?",
          content:
            "U skladu sa potrebama , sa svakim klijentom posebno možemo ugovoriti određeno vreme potrebno za obradu zahteva, zavisno od potrebne dijagnostičke metode/obima posla/urgentnosti. ",
        },
      ],
    },
    {
      type: "section",
      content: [
        {
          type: "header",
          content: "Vaše kolege već rade za nas",
        },
        {
          type: "header",
          content: "Pridružite im se!",
        },
      ],
    },
  ],
  bePartner: [
    { type: "header", content: "Postanite naš partner" },
    {
      type: "paragraphs",
      content: [
        "Teleradiologija za Vaše potrebe.",
        "TelMed pruža integralno rešenje za Vaše zdravstvene ustanove pomoću kojeg  pacijentima možete ponuditi čitav niz različitih dijagnostičkih procedura.",
        "Ako ste distributer medicinske opreme, postanite naš partner i uz pomoć naše ponude povećajte svoju prodaju. ",
      ],
    },
    {
      type: "article",
      header: "Zdravstvene ustanove",
      content: [
        "Većina zdravstvenih ustanova razume da teleradiologija predstavlja veliki napredak u medicini i budućnosti radiologije. Mi znamo da obezbeđivanje dijagnostičkih usluga snimanja Vašim pacijentima za Vas može biti veliki izazov. Sa TelMed platformom možete istovremeno premostiti problem nastao nedovoljnim brojem radiologa, osloboditi svoj već opterećen tim velikog pritiska i pacijentima ponuditi kompletnu uslugu. ",
        "Sa TelMed rešenjem, možete otvoriti, ako ga nemate, odeljenje radiologije ili povećati radiološke kapacitete u svojoj medicinskoj ustanovi.  ",
      ],
    },
    {
      type: "article",
      header: "Distributeri medicinske opreme",
      content: [
        "Da li imate problem sa prodajom radiološke opreme zbog nedostatka radiologa na Vašem tržištu? Partnerskim programom koji nudimo, TelMed ima rešenje za Vas. Vi obezbedite medicinsku opremu, a mi ćemo obezbediti specijaliste radiologije. To će povećati Vašu prodaju čak i na najudaljenijim tržištima.",
      ],
    },
    {
      type: "accordion",
      title: "sa nama se lako povezuje",
      content: [
        {
          title: "Instalacija",
          content: [
            "Nakon potpisivanja ugovora, dobićete jedinstveni ključ/identifikator i link za preuzimanje instalacije softvera (servisa) koji se izvršava u Vašem okruženju. Instalacija je prilično jednostavna i možete je izvršiti sami, a svakako uvek od naših tehničara možete tražiti pomoć. Nakon završetka instalacije i slanja probnih podataka, spremni ste da počnete.",
          ],
        },
        {
          title: "Konfiguracija",
          content: [
            "U zavisnosti od internih procedura koje se primenjuju kod Vas (postojanje RIS/HIS, skladištenje snimaka, itd) imaćete tri načina da se sa svoje lokacije povežete sa našim radiolozima.",
            "• Našu uslugu možete da konfigurišete tako da radi kao sekundarni PACS u Vašoj mreži na koji ćete povezati svoje radiološke aparate. Na taj način se svaka generisana slika automatski  šalje nama.",
            "• Ako za čuvanje snimaka koristite mrežu/lokalni folder, “watchdog” servis će pratiti svaku promenu (nove fajlove) i slati je nama.",
            "• Ukoliko ne koristite nijedan pristup od gore navedenih (ili dolazi do čestih prekida mreže, itd) arhivu možete ručno učitati (DICOMDIR datoteka) na našu veb aplikaciju, preko svog naloga.",
          ],
        },
        {
          title: "Rad na dnevnoj bazi",
          content: [
            "Nakon što nam pošaljete podatke/zahtev za konsultaciju, moći ćete da pratite kako postupak napreduje – od prijema snimka, preko upućivanja snimka doktoru do završetka konsultacije. Po završetku konsultacije, dobićete link za preuzimanje koji je generisan za Vas. Ukoliko postoji bilo kakav problem vezan za kvalitet snimka koji ste poslali TelMed-u, o tome ćete biti obavešteni.",
          ],
        },
      ],
    },
    {
      type: "section",
      content: [
        {
          type: "header",
          content: "Naš tim za podršku je uz Vas",
        },
        {
          type: "header",
          content: "24 sata 7 dana u nedelji!",
        },
      ],
    },
  ],
  consult: [
    { type: "header", content: "Budite naš konsultant" },
    {
      type: "paragraphs",
      content: [
        "Uživajte u fleksibilnosti rada na daljinu.",
        "Pridružite se našem timu iskusnih konsultanata radiologije koji pružaju teleradiološke konsultacije tržištima u razvoju. TelMed platforma radiolozima omogućava  pristup velikom broju slučajeva, fleksibilno radno vreme i redovan dodatni prihod. Pozicija radiologa konsultanta u našem timu je uvek otvorena.",
      ],
    },
    {
      type: "section",
      content: [
        { type: "header", content: "zašto telmed" },
        {
          type: "section",
          content: [
            {
              type: "article",
              header: "budite fleksibilni i naučite više",
              content: [
                "Vi sami odlučujete kada i koliko ćete raditi. Sa našim potpuno fleksibilnim modelom izveštavanja,  možete birati puno radno vreme, ili sporiji tempo koji Vam i dalje donosi dodatnu zaradu.",
              ],
            },
            {
              type: "article",
              header: "naša podrška",
              content: [
                "TelMed softversko rešenje, uz podršku našeg operativnog tima, Vam omogućava rad bez nepotrebnih prekida i pomaže Vam da se fokusirate na dijagnostički izveštaj ",
              ],
            },
            {
              type: "article",
              header: "profesionalni razvoj",
              content: [
                "Zahvaljujući mreži kolega širom Evrope imaćete pristup novim dešavanjima u razvoju radiologije.",
              ],
            },
          ],
        },
      ],
    },
    {
      type: "section",
      content: [
        {
          type: "header",
          content: "Vaše kolege već rade za nas.",
        },
        {
          type: "header",
          content: "Pridružite im se!",
        },
      ],
    },
    {
      type: "accordion",
      title: "samo nekoliko koraka vas deli od nas",
      content: [
        {
          title: "Prvi korak",
          content: [
            "…je prilično jednostavan - obratite nam se putem formulara na našem sajtu, i mi ćemo Vas kontaktirati. Priložite svoju biografiju, preporuke i sve što smatrate da može da doprinese opisu Vas i Vaše karijere. Obavezno navedite poznavanje bilo kog radiološkog softvera kojim vladate. Ali, čak i ako to ne učinite…",
          ],
        },
        {
          title: "Naš softver je lak za upotrebu",
          content: [
            "Pre svega, softver ne zahteva nikakvu instalaciju i u potpunosti je web orijentisan. Ne morate  se opterećivati CD-ovima ili DVD-evima niti koristiti komplikovane konekcije sa udaljenim serverima. Na raspolaganju su Vam:",
            "• Lista zadataka, na kojoj su izlistani snimci za koje se traži Vaša stručna konsultacija",
            "• Pregledač snimaka (viewer), koji poseduje  korisne alate za obeležavanje i merenje. Napravljen je u skladu sa svim standardima industrije",
            "• Kalendar na kome možete da izaberete dan i vreme kada ćete biti dostupni za rad tako da naši administratori mogu  da planiraju i ravnomerno raspoređuju dolazeće zahteve i zadatke ",
          ],
        },
        {
          title: "Obuka",
          content: [
            "Postoji demo sistem za obuku, gde možete da naučite kako da koristite naš sistem i date svoje mišljenje. Uspostavili smo sistem, u okviru koga će Vas dodeljeni radiolog mentor uvesti u proces. ",
          ],
        },
      ],
    },
    {
      type: "section",
      content: [
        {
          type: "header",
          content: "Naš tim za podršku i Vaš mentor su tu za Vas",
        },
        {
          type: "header",
          content: "24 sata 7 dana u nedelji!",
        },
      ],
    },
  ],
  contact: [
    { type: "header", content: "Kontakt" },
    {
      type: "article",
      header: "Radite sa nama",
      content: [
        "Molimo Vas da unesete sledeće podatke i kolege iz TelMed-a će Vas kontaktirati u kratkom roku.",
      ],
    },
    {
      type: "list",
      content: [
        "Izaberite kategoriju *",
        "Ime *",
        "Prezime *",
        "Email *",
        "Telefon br.",
        "Poruka",
        "Priložite biografiju/CV",
        "Priložite druga dokumenta",
        "Slanje",
      ],
    },
    {
      type: "list",
      content: [
        "Ja sam radiolog konsultant",
        "Predstavljam zdravstvenu ustanovu",
        "Ja sam distributer medicinske opreme",
        "Ostali",
      ],
    },
    {
      type: "header",
      content:
        "Zahvaljujemo na Vašem interesovanju, ubrzo će Vas kontaktirati naš predstavnik",
    },
    {
      type: "header",
      content: "Neispravan format adrese",
    },
  ],
  footerContent: [
    {
      type: "address",
      content: [
        { type: "text", content: "Kontakt informacije:" },
        { type: "text", content: "contact@telmed.rs" },
        { type: "text", content: "+381 11 2612 846" },
      ],
    },
    {
      type: "address",
      content: [
        { type: "text", content: "Telmed DOO" },
        { type: "text", content: "Dubrovačka 2a" },
        { type: "text", content: "11080 Zemun, Srbija" },
        { type: "link", title: "www.telmed.rs", link: "/" },
      ],
    },
    {
      type: "links",
      content: [
        { title: "O nama", link: "/" },
        { title: "Usluge", link: "services" },
        { title: "Budite naš konsultant", link: "consult" },
        { title: "Postanite naš partner", link: "partner" },
        { title: "Često postavljana pitanja", link: "faq" },
      ],
    },
  ],
};
