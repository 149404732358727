import "./raas.css";
import { Article, Banner, Image } from "../../components";
import {
  analyst1,
  analyst2,
  analyst3,
  analyst4,
  scanScreen,
  scanImage2,
  scanImage3,
} from "../../assets";

const Raas = ({ content }) => {
  return (
    <div className="raas">
      <Banner bannerHeader={content[0].content} />
      {content[1].content.map((element) => (
        <Article
          title={element.header}
          text={element.content.map((element) => (
            <p>{element}</p>
          ))}
        />
      ))}
      <Image alt="raas" source={analyst1} />
      <Article
        title={content[2].content[0].header}
        text={content[2].content[0].content.map((element) => (
          <p>{element}</p>
        ))}
      />

      <Article
        title={content[2].content[1].header}
        text={content[2].content[1].content.map((element) => (
          <p>{element}</p>
        ))}
      />

      <Image alt="raas" source={analyst2} />
      <Image alt="raas" source={scanScreen} />
      <Article
        title={content[2].content[2].header}
        text={content[2].content[2].content.map((element) => (
          <p>{element}</p>
        ))}
      />
      <Article
        title={content[2].content[3].header}
        text={content[2].content[3].content.map((element) => (
          <p>{element}</p>
        ))}
      />
      <Image alt="raas" source={scanImage2} />
      <Image alt="raas" source={analyst3} />
      <Article
        title={content[2].content[4].header}
        text={content[2].content[4].content.map((element) => (
          <p>{element}</p>
        ))}
      />
      <Article
        title={content[2].content[5].header}
        text={content[2].content[5].content.map((element) => (
          <p>{element}</p>
        ))}
      />
      <Image alt="raas" source={scanImage3} />
      <Image alt="raas" source={analyst4} />
      <Article
        title={content[2].content[6].header}
        text={content[2].content[6].content.map((element) => (
          <p>{element}</p>
        ))}
      />
    </div>
  );
};

export default Raas;
