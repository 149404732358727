import { useState, createContext, useContext, useRef } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as BurgerIcon } from "src/assets/icons/burg-menu.svg";
import OutsideClickPlugin from "src/hooks/OutsideClickPlugin";

const ToggleContext = createContext(null);

const handleExpanded = (evt) => {
  const titleClasses = evt.target.classList;
  const menuClasses = evt.target.nextSibling.classList;

  if (menuClasses.contains("sub-menu")) {
    menuClasses.replace("sub-menu", "sub-menu-expanded");
    titleClasses.add("sub-title-expanded");
  } else {
    menuClasses.replace("sub-menu-expanded", "sub-menu");
    titleClasses.remove("sub-title-expanded");
  }
};

const SubMenu = ({ submenu, title, className = "" }) => {
  const setMenuExpanded = useContext(ToggleContext);
  const mapped = submenu.map((item) => {
    const { title, link } = item;
    return (
      <li key={title} onClick={() => setMenuExpanded(false)}>
        <Link to={`/${link}`}>{title}</Link>
      </li>
    );
  });

  return (
    <li className="sub-container">
      <span className="sub-title" onClick={handleExpanded}>
        {title}
      </span>
      <ul className="sub-menu">{mapped}</ul>
    </li>
  );
};

const Menu = ({ linkList }) => {
  const setMenuExpanded = useContext(ToggleContext);
  const mapped = linkList.map((item) => {
    const { type, title, link, subMenu } = item;
    if (type === "submenu") {
      return <SubMenu submenu={subMenu} title={title} />;
    }

    return (
      <li key={title} onClick={() => setMenuExpanded(false)}>
        <Link to={`/${link}`}>{title}</Link>
      </li>
    );
  });

  return <ul className="burger-menu">{mapped} </ul>;
};

const BurgerMenu = ({ linkList }) => {
  const [menuExpanded, setMenuExpanded] = useState(false);
  const containerRef = useRef(null);

  return (
    <ToggleContext.Provider value={setMenuExpanded}>
      <div ref={containerRef} className="burger-container">
        <BurgerIcon
          width="20"
          height="20"
          className="menu-icon"
          alt="navigation"
          onClick={() => setMenuExpanded(!menuExpanded)}
        />
        {menuExpanded && (
          <>
            <Menu linkList={linkList} />
            <div
              onClick={() => setMenuExpanded(false)}
              className="burger-menu-bck"
            ></div>
          </>
        )}
        <OutsideClickPlugin
          callback={() => setMenuExpanded(false)}
          container={containerRef.current}
        />
      </div>
    </ToggleContext.Provider>
  );
};

export default BurgerMenu;
