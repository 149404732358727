import "./faq.css";
import { AccordionItem, Banner, PromoBanner } from "../../components";
import { scanImage1Right } from "../../assets";

const Faq = ({ faqContent }) => {
  return (
    <div className="faq">
      <Banner bannerHeader={faqContent[0].content} />
      <div className="accordion">
        {faqContent[1].content.map((element) => (
          <AccordionItem title={element.title} content={element.content} />
        ))}
      </div>
      <PromoBanner
        content={
          <>
            {faqContent[2].content.map((element) => (
              <h2>{element.content}</h2>
            ))}
          </>
        }
        overlayColor="#222a35e6"
        backImage={scanImage1Right}
      />
    </div>
  );
};

export default Faq;
