import {
  Banner,
  Article,
  Image,
  AccordionItem,
  PromoBanner,
} from "../../components";
import { analysts, mriDevice, deskConsult2 } from "../../assets";
import { Features } from "../";
import "./partner.css";

const Partner = ({ partnerContent, featuresContent }) => {
  return (
    <div className="partner">
      <Banner bannerHeader={partnerContent[0].content} />
      <Image source={analysts} />
      <Article
        className="back-green"
        title={partnerContent[2].header}
        text={partnerContent[2].content.map((element) => (
          <p>{element}</p>
        ))}
      />
      <Article
        className="back-green"
        title={partnerContent[3].header}
        text={partnerContent[3].content.map((element) => (
          <p>{element}</p>
        ))}
      />
      <Image source={mriDevice} />
      <Features featuresContent={featuresContent} />
      <div className="accordion">
        <h2>{partnerContent[4].title}</h2>
        {partnerContent[4].content.map((element) => (
          <AccordionItem
            title={element.title}
            content={element.content.map((element) => (
              <p>{element}</p>
            ))}
          />
        ))}
      </div>
      <PromoBanner
        content={
          <>
            {partnerContent[5].content.map((element) => (
              <h2>{element.content}</h2>
            ))}
          </>
        }
        overlayColor="#222a35e6"
        backImage={deskConsult2}
      />
    </div>
  );
};

export default Partner;
