import "./systemIntegration.css";
import { Article, Banner, Image } from "../../components";
import { analyst1, analyst2, analyst3, analyst4 } from "../../assets";

const Integration = ({ content }) => {
  return (
    <div className="integration">
      <Banner bannerHeader={content[0].content} />
      {content[1].content.map((element) => (
        <Article
          title={element.header}
          text={element.content.map((element) => (
            <p>{element}</p>
          ))}
        />
      ))}
      <Image alt="system integration" source={analyst1} />
      <Image alt="system integration" source={analyst3} />
      <Article
        title={content[2].content[0].header}
        text={content[2].content[0].content.map((element) => (
          <p>{element}</p>
        ))}
      />
      <Article
        title={content[3].content[0].header}
        text={content[3].content[0].content.map((element) => (
          <p>{element}</p>
        ))}
      />
      <Image alt="system integration" source={analyst2} />
      <Image alt="system integration" source={analyst4} />
      <Article
        title={content[4].content[0].header}
        text={content[4].content[0].content.map((element) => (
          <p>{element}</p>
        ))}
      />
    </div>
  );
};

export default Integration;
