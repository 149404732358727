import { createContext, useContext } from "react";
import { useMediaQuery } from "./hooks";

const MediaQueryContext = createContext();

export const MediaQueryProvider = ({ width, ...props }) => {
  const isDesktop = useMediaQuery("width", width);

  return (
    <MediaQueryContext.Provider
      value={isDesktop ? "desktop" : "mobile"}
      {...props}
    />
  );
};

export const useMediaContext = () => {
  const context = useContext(MediaQueryContext);

  if (!context) {
    throw new Error("useMediaContext must be used within MediaQueryProvider");
  }
  return context;
};
