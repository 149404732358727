import "./accordionItem.css";
import chevronDown from "../../assets/icons/chevron-down.svg";
import chevronUp from "../../assets/icons/chevron-up.svg";
import { useRef, useState } from "react";

const AccordionItem = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);
  const accordContent = useRef(null);

  const accordFunction = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      <div className="accordion-item">
        <div className="accordion-title" onClick={(evt) => accordFunction(evt)}>
          <div>{title}</div>
          <img src={isActive ? chevronUp : chevronDown} alt="chevron" />
        </div>
        {isActive && (
          <div className="accordion-content" ref={accordContent}>
            {content}
          </div>
        )}
      </div>
    </>
  );
};

export default AccordionItem;
