export const contentEnglish = {
  navigation: [
    // { type: "link", title: "services", link: "services" },
    {
      type: "submenu",
      title: "solutions",
      link: "solutions",
      subMenu: [
        {
          type: "link",
          title: "Radiologist as a Service - RaaS",
          link: "raas",
        },
        {
          type: "link",
          title: "ExaWorkS - SaaS",
          link: "exaworks",
        },
        { type: "link", title: "TelMed PACS solutions ", link: "pacs" },
        { type: "link", title: "System Integration", link: "integration" },
      ],
    },
    { type: "link", title: "About us", link: "workUs" },
    {
      type: "submenu",
      title: "work with us",
      link: "about",
      subMenu: [
        { type: "link", title: "Consult for Us", link: "consult" },
        { type: "link", title: "Be our partner", link: "partner" },
      ],
    },
    { type: "link", title: "Faq", link: "faq" },
    { type: "link", title: "Contact", link: "contact" },
    // { type: "link", title: "News", link: "news" },
  ],
  buttons: {
    readMore: "read more",
  },
  opener: [
    {
      type: "article",
      header: "European radiology expertise for frontier markets",
      content: [
        "Innovative solution for frontier markets. We bring the experience of thousands of European doctors, offering a turnkey solution for essential support to healthcare providers.",
        "These services provide hospital radiology departments with rapid access to reporting capacity that is not available within their organization.",
      ],
    },
    {
      type: "article",
      header: "Future for everyone",
      content: [
        "I believe teleradiology is introducing us to a new digital medical era that is taking place around the world.",
        "Personally, I look forward to future collaboration with radiologists and partners globally, and to bringing European standards in radiology to frontier markets.",
        "Ivan Jurčić, Chief Executive",
      ],
    },
  ],
  raas: [
    { type: "header", content: "Radiologist as a Service (RaaS)" },
    {
      type: "section",
      content: [
        {
          type: "article",
          header: "Teleradiology consulting for your non-urgent needs",
          content: [
            "TelMed is providing you with remote consulting support that does not require reporting within a specified timeframe. We can offer support for diagnostic modalities such as conventional radiography (CR), computerized tomography (CT), and magnetic resonance imaging (MRI).",
            "An extensive panel of Europe-based radiologists backs our flexible and dependable service.",
          ],
        },
        {
          type: "article",
          header: "Teleradiology urgent consulting support",
          content: [
            "TelMed offers you daytime (08-20h CET) and nighttime (20-08h CET) urgent services that run 7 days a week, providing you with full teleradiology solutions for your in-house needs, alleviating staff pressure, and providing a tool to enhance radiology practice.",
            "Our urgent consulting services give you immediate access to Europe-based consultant radiologists experienced in emergency care.",
            "Our 24/7 support gives access to our administrators for all out-of-hours radiology consulting requests.",
          ],
        },
        {
          type: "article",
          header: "Specialist reporting",
          content: [
            "A radiology specialist report is a crucial part of communication between the radiologist and the referring physician.",
            "TelMed platform is providing you with a structured final report done by our highly experienced radiologists. ",
          ],
        },
      ],
    },
    {
      type: "section",
      content: [
        {
          type: "article",
          header: "Head, neck and spine imaging",
          content: [
            "We provide detailed and precise interpretation and reporting of the common and less common head, neck, and spine pathology. Trauma, inflammatory/infective etiology, cerebrovascular insults, neoplastic and metastatic processes, metabolic diseases, and very frequent spine disc protrusions are common causes for using radiologic imaging modalities such as CT and MRI. For evaluation of bony structures of the head and spine in situations where CT and MRI diagnostics are unavailable there is a frequent need for basic CR exploration.",
          ],
        },
        {
          type: "article",
          header: "Chest imaging",
          content: [
            "As in other systems, numerous pathologic conditions can affect the lung and heart. Inflammatory and infective diseases, various tumors, congenital and autoimmune diseases, and traumatic disorders are often indications for chest CR and CT exploration.",
            "Experienced TelMed radiologists are ready to evaluate the most complicated chest pathology.",
          ],
        },
        {
          type: "article",
          header: "Vascular imaging",
          content: [
            "The vascular system as a complex network of blood vessels can be evaluated with imaging modalities like CT angiography (arteriography and phlebography) and MR angiography (arteriography and phlebography). Congenital anomalies like aneurysms and AV malformations, other congenital vascular aberrations, vascular stenosis, and pre-procedural/preoperative planning are the most important indications evaluated with CT and MR imaging.",
            "TelMed radiologists will provide you with a precise interpretation of received CT /MR tomograms.",
          ],
        },
        {
          type: "article",
          header: "Mammography & breast MR imaging",
          content: [
            "Mammography is an imaging modality used in situations such as fatty breast where ultrasound is an insufficient diagnostic method, previously detected breast architectural distortion and tumorous lesions, suspicious breast calcifications, palpable resistance in breast not visualized on ultrasound imaging, early detection of breast cancer in women at high risk and after previous lumpectomy or mastectomy.",
            "Common causes for breast MRI explorations may include: evaluation of mammography detected abnormalities (interpreting exact location, size, and several previously detected breast lesions together with axillary regions; detection of cancer spread into the chest wall which could change treatment approaches, etc); after breast augmentation where scar tissue may be suspicious of breast cancer on a mammogram; and the detection of silicone gel implant leakage.",
            "With TelMed and our medical experts, you can obtain a professional medical opinion and a high-quality reporting system.",
          ],
        },
        {
          type: "article",
          header: "Abdominal and pelvic imaging",
          content: [
            "Abdominal and pelvic pathology is among the most frequent reasons to use CT and MRI imaging modalities. Unfortunately, there is a global expansion of malignant disease and therefore a great need to apply CT and MR-based diagnostics. Neoplasms of the colon, hepatobiliary tract, kidney and bladder, as well as prostatic and gynecologic malignancies, hepatic metastases, and abdominal and pelvic lymphadenopathy, are frequently seen pathology. Precise detection of primary tumor location and eventually locoregional and distant metastases with staging reports are crucial for planning further medical procedures.",
            "TelMed experts provide an efficient and professional medical opinion in the area of abdominal and pelvic pathology.",
          ],
        },
        {
          type: "article",
          header: "Musculoskeletal reporting",
          content: [
            "Nowadays we can detect numerous musculoskeletal pathology using imaging modalities such as CR, CT, and MRI.  CT and MRI modalities have a big role in evaluation of bone tumors. With a typical appearance of some pathologic changes, CT or MRI with high specificity and sensitivity provide preliminary diagnoses (for example bone tumors such as enchondroma, NOF, osteosarcoma, Ewing sarcoma). Besides that, a wide range of inflammatory, degenerative, metabolic and metastatic disorders affect the skeletal system and may demand further CT or MR diagnostic.",
            "Our radiologist can easily provide you with a professional opinion in form of a preliminary or final report.",
          ],
        },
        {
          type: "article",
          header: "Pediatrics",
          content: [
            "A radiologist first must consider the benefits and risks of exposing a patient to ionizing radiation imaging modalities such as CR and CT. Unfortunately, children in specific conditions and with more serious pathology require CR and CT diagnostic imaging. MRI is a non-ionizing imaging modality so it is safe (except for some contraindications) for use among children population.",
            "TelMed teleradiology platform can provide a high-quality reporting system in the area of pediatrics after the previous diagnostics with imaging modalities such as CR, CT, and MRI.",
          ],
        },
      ],
    },
  ],
  exaworks: [
    { type: "header", content: "TelMed ExaWorkS (SaaS)" },
    {
      type: "section",
      content: [
        {
          type: "article",
          header: "Introducing ExaWorkS",
          content: [
            "The ultimate SaaS solution for seamless radiology workflow management within healthcare institutions.",
            "ExaWorkS sits on top of a robust PACS foundation,",
            "providing an all-in-one platform that simplifies the entire radiology process from image acquisition, to report generation.",
          ],
        },
      ],
    },
    {
      type: "section",
      content: [
        {
          type: "article",
          header: "Custom Integration",
          content: [
            "Custom Integration",
            "ExaWorkS is built on a powerful PACS solution, ensuring secure, scalable, and efficient storage and retrieval of radiological studies.",
            "This foundation guarantees that all medical imaging data is easily accessible and safely archived.",
          ],
        },
        {
          type: "article",
          header: "Advanced Ticketing System",
          content: [
            "Our platform includes an intuitive ticketing system that allows clinic administrators to assign studies to radiologists based on their specialties.",
            "This ensures that each case is handled by the most qualified professional, enhancing the quality and speed of diagnoses.",
          ],
        },
        {
          type: "article",
          header: "Seamless Study Assignment",
          content: [
            "With automated study assignments, ExaWorkS leverages smart algorithms to distribute cases among radiologists, optimizing workload balance and ensuring timely review and reporting.",
          ],
        },
        {
          type: "article",
          header: "Integrated Web-Based TelMed DICOM Viewer",
          content: [
            "Radiologists can access and analyze studies using the integrated TelMed DICOM Viewer, a sophisticated web-based tool equipped with advanced image processing features.",
            "No installation is required, making it accessible from anywhere via a web browser.",
          ],
        },
        {
          type: "article",
          header: "Efficient Report Writing with Templates",
          content: [
            "ExaWorkS includes comprehensive report templates that streamline the reporting process. Radiologists can write detailed reports directly to the viewer, using standardized templates that ensure consistency and thoroughness.",
          ],
        },
        {
          type: "article",
          header: "Zero-Footprint Access",
          content: [
            "Our zero-footprint viewer ensures that no patient data is left on the client machine, enhancing security and compliance with privacy regulations.",
            "Radiologists can perform measurements and utilize advanced tools without worrying about data leakage.",
          ],
        },
        {
          type: "article",
          header: "",
          content: [
            "Multi-Location Support",
            "ExaWorkS is designed to support healthcare institutions with one or multiple locations, providing a unified platform for managing radiology workflows across different sites.",
          ],
        },
      ],
    },
  ],
  pacs: [
    { type: "header", content: "TelMed PACS solutions" },
    {
      type: "section",
      content: [
        {
          type: "article",
          header: "TelMed PACS",
          content: [
            "New and improved solution for storing and managing medical data by utilizing cutting edge technologies.",
            "This approach offers several advantages over traditional on-site PACS systems:",
          ],
        },
      ],
    },
    {
      type: "section",
      content: [
        {
          type: "article",
          header: "Storage and Features",
          content: [
            "Hosting and Retrieval: TelMed PACS handles the hosting and retrieval of medical imaging and clinical documents either in cloud or locally.",
            "Maintenance: We provide continuous maintenance, ensuring that the system remains up-to-date and functional.",
          ],
        },
        {
          type: "article",
          header: "Data Upload",
          content: [
            "DICOM Images: Upload a variety of DICOM images such as CT, MRI, X-ray, etc.",
            "Non-DICOM Documents: Store non-DICOM documents like JPEG, BMP, PNG, MP4, PDF, etc., making it versatile for various types of medical data.",
          ],
        },
        {
          type: "article",
          header: "Functionality",
          content: [
            "Storage: Securely store all medical imaging and documents in one place.",
            "Viewing: Access and view images and documents through the TelMed PACS interface.",
            "Reporting: Create detailed reports that can be shared with other healthcare professionals and patients.",
            "Sharing: Easily share images and documents with patients and doctors through secure links.",
          ],
        },
        {
          type: "article",
          header: "Disaster PACS Backup & Recovery",
          content: [
            "Data Security: Our Disaster PACS ensures that your data is protected against loss with Backup & Recovery service.",
            "Recovery: In the event of a disaster, your data can be quickly and reliably recovered, ensuring continuity of care.",
          ],
        },
        {
          type: "article",
          header: "Sharing Images",
          content: [
            "No More DICOM CDs",
            "- Avoid the hassle of burning and distributing DICOM CDs.",
            "- Use TelMed PACS to easily share images and clinical documents in the cloud or at your local servers.",
            "Creating Share Links",
            "- Secure Sharing: Create a share link with an access password for the secure sharing of images and documents.",
            "- Immediate Access: Recipients can access the shared images and documents immediately upon receiving the link and password.",
            "User-Friendly Access",
            "- For Patients and Doctors: Both patients and doctors can easily access shared images and documents without needing specialized software.",
            "- Convenience: This method simplifies the sharing process, making it faster and more efficient.",
          ],
        },
        // {
        //   type: "article",
        //   header: "Creating Share Links",
        //   content: [
        //     "- Secure Sharing: Create a share link with an access password for the secure sharing of images and documents.",
        //     "- Secure Sharing: Create a share link with an access password for the secure sharing of images and documents.",
        //     "Recovery: In the event of a disaster, your data can be quickly and reliably recovered, ensuring continuity of care.",
        //   ],
        // },
      ],
    },
  ],
  systemIntegration: [
    { type: "header", content: "System Integration" },
    {
      type: "section",
      content: [
        {
          type: "article",
          header: "We offer enterprise solutions",
          content: [
            "System integration can be carried out in various ways and at different levels.",
            "Thanks to our extensive experience, we can meet all client requirements by executing the project according to the following plan:",
          ],
        },
        {
          type: "article",
          header: "Analysis",
          content: [
            "Requirement Gathering: Understanding the client's needs and existing infrastructure.",
            "Feasibility Study: Assessing the practicality and potential impact of integration.",
            "Gap Analysis: Identifying any gaps between current capabilities and desired outcomes.",
          ],
        },
      ],
    },
    {
      type: "section",
      content: [
        {
          type: "article",
          header: "Planning, Design, and Development",
          content: [
            "Project Planning: Defining project scope, timelines, and resource allocation.",
            "System Design: Creating detailed architectural designs and integration blueprints.",
            "Gap Analysis: Identifying any gaps between current capabilities and desired outcomes.",
          ],
        },
      ],
    },
    {
      type: "section",
      content: [
        {
          type: "article",
          header: "Implementation",
          content: [
            "Installation: Setting up TelMed solutions within the client's environment.",
            "Integration: Ensuring all components work cohesively with existing systems.",
            "Data Migration: Safely transferring data from legacy systems to the new setup.",
          ],
        },
      ],
    },
    {
      type: "section",
      content: [
        {
          type: "article",
          header: "Maintenance and Support",
          content: [
            "Technical Support: Offering continuous assistance for any issues reported by the client.",
            "Problem Resolution: Addressing and resolving any technical problems promptly.",
            "Monitoring and Maintenance: Regularly overseeing and maintaining both hardware and software components to ensure optimal performance.",
            "Our role as system integrators encompasses these services to guarantee the efficient operation of medical diagnostic systems.",
            "In addition to providing technical support when issues arise, we regularly monitor and maintain the hardware and software components of the medical diagnostic system for our partners. This proactive approach helps prevent potential problems and ensures the longevity and reliability of the systems.",
          ],
        },
      ],
    },
  ],
  aboutUs: [
    { type: "header", content: "About us" },
    {
      type: "article",
      header: "Teleradiology for frontier markets",
      content: [
        "TelMed aims to become the leading provider of teleradiology in frontier markets following the establishment of its turnkey business solutions. Our goal is to offer European radiology expertise to healthcare providers who lack additional expertise and resources.",
        "We tend to think that most of the new technology is made for the developed world. What if, in this case, the technology is destined to change the way that medicine, in general, is both perceived and practiced in Central and Eastern Europe, remote areas of Africa, Asia, the Middle East, and South America.",
        "TelMed wants to lead the change!",
      ],
    },
    {
      type: "accordion",
      title: "our goals",
      content: [
        {
          title: "QUALITY",
          content:
            "The teleradiology platform will create access to leading radiologists from Europe, providing diagnostic accuracy and reducing the response of medical staff in patient diagnostics through TelMed smart software and operational models.",
        },
        {
          title: "FLEXIBILITY",
          content:
            "We want to maintain the highest standard in radiology, with maximum flexibility, both for radiologists and healthcare providers at affordable prices, and to deliver the service to you, wherever you are.",
        },
        {
          title: "INNOVATION",
          content:
            "TelMed automated translation service will ensure the accurate delivery of diagnostic reports adapted for your market and local language.",
        },
        {
          title: "OPENNESS",
          content:
            "The platform provides a simple way to share and discuss patient images between radiology experts and colleagues from other medical disciplines for more precise and rapid diagnosis providing potentially better outcomes for our patients.",
        },
      ],
    },
    {
      type: "article",
      header: "Future for everyone",
      content: [
        "I believe teleradiology is introducing us to a new digital medical era that is taking place around the world. Personally, I look forward to future collaboration with radiologists and partners globally, and to bringing European standards in radiology to frontier markets.",
        "Ivan Jurčić, Chief Executive",
      ],
    },
  ],
  features: [
    { type: "header", content: "the telmed platform offers" },
    {
      type: "section",
      content: [
        {
          type: "article",
          header: "Fast and accurate reporting",
          content: [
            "TelMed teleradiology platform will give you access to European radiologists, creating a framework for reducing the response time of medical staff in patient diagnostics.",
          ],
        },
        {
          type: "article",
          header: "Full-service offering",
          content: [
            "We provide access to radiologists, software, IT infrastructure, report translation, and tech support. With us, you get all that you need to upgrade your diagnostic capacity.",
          ],
        },
        {
          type: "article",
          header: "Report language localization",
          content: [
            "The language you understand: TelMed automated translation service will ensure accurate delivery of the diagnostic reports adapted for your market and local language.",
          ],
        },
        {
          type: "article",
          header: "Advanced technology infrastructure",
          content: [
            "TelMed software is capable of imaging sharing in minutes and providing reports for fast and effective patient management, even in the most remote areas of the world. Our IT infrastructure is flexible and optimized so we can connect to your RIS/PACS wherever you are with a focus on data security and patient privacy.",
          ],
        },
        {
          type: "article",
          header: "Cost-effective service",
          content: [
            "Save your money and resources with TelMed. Our platform offers cost-efficiency through a wide network of European radiologists.  ",
          ],
        },
        {
          type: "article",
          header: "Partnership",
          content: [
            "We want to understand your needs and meet all of your requirements. If you are Health Care Provider or Medical Equipment distributor, we are your reliable partner!",
          ],
        },
      ],
    },
  ],
  faq: [
    { type: "header", content: "faq" },
    {
      type: "accordion",
      content: [
        {
          title: "Are all radiologists working for TelMed based in Europe?",
          content:
            "Yes, all of our radiologists are based in Europe. All of TelMed's radiologists have strong knowledge of the reporting procedures and extensive experience in working within European standards.",
        },
        {
          title:
            "Will there be connectivity costs or ongoing administration and maintenance costs?",
          content:
            "There are no connectivity costs for the TelMed’s basic connectivity. Any equipment which may need to be on-site will be installed and supported by TelMed. All we require is minimal involvement from your IT department in establishing the connection. ",
        },
        {
          title: "What is the time required for set-up?",
          content:
            "With your support, you will have fast integration with multiple modes adjusted to the clinic's technological infrastructure. After a relatively simple installation, that will be guided by our technician, you will be up and running. No lengthy configuration (VPN or similar) is required - all the communication is done via HTTPS.",
        },
        {
          title: "How do we connect?",
          content:
            "Simply fill out the contact form and we will get back to you.",
        },
        {
          title:
            "Will the remote reporting radiologists have access to all the clinically relevant information?",
          content:
            "TelMeds' radiologists have access to all of the clinically relevant information. All reports are completed making appropriate use of patient referrals and relevant diagnostic images.",
        },
        {
          title: "Are there procedures in place to monitor the contract?",
          content:
            "Our software allows constant monitoring of SLA targets, and key account managers regularly monitor that process.  Our team will be available to you for any questions you might have.  ",
        },
        {
          title: "Do you provide sub-specialist reporting?",
          content:
            "Yes, TelMed provides a broad range of sub-specialist reporting. If required our radiologists are available for a multi-disciplinary team meeting. ",
        },
        {
          title: "What are the turnaround times?",
          content:
            "In agreement with the client, turnaround can be arranged with each customer depending on the required modality/volumes/urgency. ",
        },
      ],
    },
    {
      type: "section",
      content: [
        {
          type: "header",
          content: "Your colleagues are already working for us",
        },
        {
          type: "header",
          content: "Feel free to join them!",
        },
      ],
    },
  ],
  bePartner: [
    { type: "header", content: "Be our partner" },
    {
      type: "paragraphs",
      content: [
        "Teleradiology for your needs.",
        "TelMed is providing a turnkey solution for your Healthcare Institutions. Offer your patients a full range of imaging services.",
        " If you are a Medical Equipment Distributor, become our partner and increase your sales based on our offer.",
      ],
    },
    {
      type: "article",
      header: "Health care providers",
      content: [
        "Most medical institutions understand that teleradiology is a major advancement in medical science and the future of radiology. We know that providing your patients with a full range of imaging services can be a challenge. With TelMed platform you can compensate for your lack of radiologists, relieve the pressure on your busy teams and offer full patient service.",
        "With TelMed solution, if you don't have one, you can open The Department of Radiology or increase radiological capabilities in your medical institution.",
      ],
    },
    {
      type: "article",
      header: "Medical equipment distributors",
      content: [
        "Do you have a problem with selling your radiological equipment because of the lack of radiologists in your market? TelMed is offering a solution for you with our partnership program. You provide medical equipment, and we will provide medical specialists.  This will boost your sales even in the most remote markets.",
      ],
    },
    {
      type: "accordion",
      title: "It's easy to be our partner",
      content: [
        {
          title: "Installation",
          content: [
            "After the contract has been signed, you will receive the unique key/identifier and a download link for our client-based service that needs to run in your environment. The installation is pretty straightforward, and you can do it by yourself, but you can always request one of our technicians to assist you. After the installation has been completed, and some test data sent, you are ready to start. ",
          ],
        },
        {
          title: "Configuration",
          content: [
            "Depending on how you handle your affairs internally (presence of RIS/HIS, image storage, etc.) you will have 3 ways to connect to our radiologists from your site.",
            "• You can configure our service to work as a secondary PACS in your network and connect your imaging devices to it. This way, every image generated will be sent to us automatically.",
            "• If you use a network/local folder to store your images, the “watchdog” service will monitor it for the changes (new files) and send them to us.",
            "• In case you don’t use any of the approaches above (or you are experiencing network outages, etc.) you can manually upload archives (DICOMDIR structure) to our web application, using your account. You are also able to use all 3 options simultaneously.",
          ],
        },
        {
          title: "Day to day work",
          content: [
            "After the data/request for a consultation has been sent to us, you will be able to monitor the progress –received image, assigned to the doctor, and completed consultation. For the completed consultation, there will be a download link generated for you when the consultation is completed. If there are any problems with the quality of the image being sent to TelMed you will be informed. ",
          ],
        },
      ],
    },
    {
      type: "section",
      content: [
        {
          type: "header",
          content: "Our support team is there for you",
        },
        {
          type: "header",
          content: "24/7!",
        },
      ],
    },
  ],
  consult: [
    { type: "header", content: "Consult for us" },
    {
      type: "paragraphs",
      content: [
        "Enjoy the flexibility of remote working.",
        "Join our team of experienced consultant radiologists providing teleradiology reporting to frontier markets. TelMed platform allows radiologists access to a large number of cases, flexible working hours, and regular additional income. We are always looking for consultant radiologists to join our network.",
      ],
    },
    {
      type: "section",
      content: [
        { type: "header", content: "why telmed" },
        {
          type: "section",
          content: [
            {
              type: "article",
              header: "Be flexible and earn more",
              content: [
                "You decide independently when and how long to work. With our completely flexible reporting model, you can be full-time employee or retiree, and still make extra earnings.",
              ],
            },
            {
              type: "article",
              header: "Our support",
              content: [
                "TelMed software solutions, with the support of our operations team, allow you to work seamlessly and focus on the report.",
              ],
            },
            {
              type: "article",
              header: "Professional development",
              content: [
                "You will have access to new advances in the development of radiology through a network of colleagues across Europe and knowledge flow via peer review structures.",
              ],
            },
          ],
        },
      ],
    },
    {
      type: "section",
      content: [
        {
          type: "header",
          content: "Your colleagues are already working for us",
        },
        {
          type: "header",
          content: "Feel free to join them!",
        },
      ],
    },
    {
      type: "accordion",
      title: "In just a few steps consult for us",
      content: [
        {
          title: "First step",
          content: [
            "…is actually quite simple - contact us, using the form on our website, and we will get in touch. Please upload your resume, references, and anything you consider relevant to describe yourself and your career. Please make sure to mention knowledge of any radiology-related software that you have. But, even if you don’t…",
          ],
        },
        {
          title: "Our software is easy to use",
          content: [
            "First of all, it is entirely web-based. So, no installation is required, you don’t have to bother with CDs or DVDs, or use some awkward remote connections to detached servers. You will work with:",
            "• A task list, where images that need your professional consultation are listed",
            "• Viewer, in which the images are displayed for your convenience, equipped with the widely used marking and measuring tools, following the look-and-feel of analyzing standard",
            "• Calendar in which you can (but you don’t necessarily have to) select the days and times when you will be available to work so that our administrators can plan and distribute the incoming requests/tasks in an even fashion",
          ],
        },
        {
          title: "Training",
          content: [
            "There is a demo system, where you will be able to learn how to use our system and provide your opinion. We have established a mentoring system, where an assigned experienced radiologist will introduce you to the process.",
          ],
        },
      ],
    },
    {
      type: "section",
      content: [
        {
          type: "header",
          content: "Our support team and your mentor are there for you",
        },
        {
          type: "header",
          content: "24/7!",
        },
      ],
    },
  ],
  contact: [
    { type: "header", content: "Contact" },
    {
      type: "article",
      header: "Work with us",
      content: [
        "Please complete your details and a TelMed colleague will contact you shortly.",
      ],
    },
    {
      type: "list",
      content: [
        "Choose Category *",
        "First Name *",
        "Last Name *",
        "Email *",
        "Phone",
        "Message",
        "Upload a resume/CV",
        "Upload other Documents",
        "Send Message",
      ],
    },
    {
      type: "list",
      content: [
        "Doctor",
        "I am a healthcare provider",
        "I am a medical equipment distributor",
        "Other",
      ],
    },
    {
      type: "header",
      content: "Thank you for your info, we will contact you soon",
    },
    {
      type: "header",
      content: "Incorrect address format",
    },
  ],
  footerContent: [
    {
      type: "address",
      content: [
        { type: "text", content: "Contact Info:" },
        { type: "text", content: "contact@telmed.rs" },
        { type: "text", content: "+381 11 2612 846" },
      ],
    },
    {
      type: "address",
      content: [
        { type: "text", content: "Telmed DOO" },
        { type: "text", content: "Dubrovacka 2a" },
        { type: "text", content: "11080 Zemun, Serbia" },
        { type: "link", title: "www.telmed.rs", link: "/" },
      ],
    },
    {
      type: "links",
      content: [
        { title: "About us", link: "/" },
        { title: "Services", link: "services" },
        { title: "Consult for us", link: "consult" },
        { title: "Be our partner", link: "partner" },
        { title: "FAQ", link: "faq" },
      ],
    },
  ],
};
