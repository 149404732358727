import { SlideShow, Article, Image } from "../../components";
import {
  deskConsult3Right,
  scanImage1Left,
  scanImage1Right,
} from "../../assets";
import Features from "../features/Features";
import { Link } from "react-router-dom";

import "./home.css";

const Home = ({
  slideContent,
  aboutContent,
  consultContent,
  bePartnerContent,
  featuresContent,
  buttons,
}) => {
  return (
    <div className="home">
      <div className="show-container">
        <SlideShow slides={slideContent} />
      </div>
      <Image alt="medical desktop" source={deskConsult3Right} />
      <Article
        className="back-white"
        title={aboutContent[1].header}
        text={<p>{aboutContent[1].content[0]}</p>}
      />
      <Article
        className="back-green"
        text={
          <>
            <p>{aboutContent[1].content[1]}</p>
            <p className="bold">{aboutContent[1].content[2]}</p>
          </>
        }
      />
      <Image alt="radio image" source={scanImage1Left} />
      <Article
        className="back-trans consult-us"
        title={consultContent[0].content}
        text={
          <>
            {consultContent[1].content.map((element) => (
              <p>{element}</p>
            ))}
            <Link className="link" to="consult">
              {buttons.readMore}
            </Link>
          </>
        }
      />
      <Article
        className="be-partner back-green"
        title={bePartnerContent[0].content}
        text={
          <>
            {
              <>
                <p>{bePartnerContent[1].content[0]}</p>
                <p>{bePartnerContent[1].content[1]}</p>
              </>
            }
            <Link className="link" to="partner">
              {buttons.readMore}
            </Link>
          </>
        }
      />
      <Image alt="scanimage held" source={scanImage1Right} />
      <Features featuresContent={featuresContent} />
    </div>
  );
};

export default Home;
