import {
  Banner,
  Article,
  Image,
  AccordionItem,
  PromoBanner,
} from "../../components";
import { mriOperators, deskConsult2 } from "../../assets";
import { Features } from "../";
import "./consult.css";

const Consult = ({ content }) => {
  const featureContent = content[2].content;
  return (
    <div className="consult">
      <Banner bannerHeader={content[0].content} />
      <Image alt="consult" source={mriOperators} />
      <Article
        className={"back-green"}
        text={content[1].content.map((element) => (
          <p>{element}</p>
        ))}
      />
      <Features featuresContent={featureContent} />
      <PromoBanner
        content={
          <>
            {content[3].content.map((element) => (
              <h2>{element.content}</h2>
            ))}
          </>
        }
        overlayColor="#222a35e6"
        backImage={deskConsult2}
      />
      <div className="accordion">
        <h2>{content[4].title}</h2>
        {content[4].content.map((element) => (
          <AccordionItem
            title={element.title}
            content={element.content.map((element) => (
              <p>{element}</p>
            ))}
          />
        ))}
      </div>
      <PromoBanner
        content={
          <>
            {content[5].content.map((element) => (
              <h2>{element.content}</h2>
            ))}
          </>
        }
        overlayColor="#222a35e6"
        backImage={deskConsult2}
      />
    </div>
  );
};

export default Consult;
