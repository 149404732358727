import "./promoBanner.css";

export const PromoBanner = ({ content, overlayColor, backImage }) => {
  const bannerStyle = {
    background: `linear-gradient(${overlayColor}, ${overlayColor}), url(${backImage})`,
  };
  return (
    <div style={bannerStyle} className="promo-banner">
      {content}
    </div>
  );
};

export default PromoBanner;
