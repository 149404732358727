import { Banner } from "../../components";
import "./contact.css";

const Contact = ({ contactContent }) => {
  return (
    <div className="contact">
      <Banner bannerHeader={contactContent[0].content} />
    </div>
  );
};

export default Contact;
