import { useState, useEffect, useRef } from "react";
import { DotButton, Article } from "../../components";

import "./slideShow.css";

const SlideShow = ({ slides }) => {
  const [state, setState] = useState({
    activeIndex: 0,
  });
  const { activeIndex } = state;
  const slideContainer = useRef(null);
  const btnContainer = useRef(null);
  const autoPlayRef = useRef();

  useEffect(() => {
    autoPlayRef.current = nextSlide;
  });

  useEffect(() => {
    const play = () => {
      autoPlayRef.current();
    };
    const interval = setInterval(play, 5000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  useEffect(() => {
    slideContainer.current.style.width = `${100 * slides.length}%`;
  });

  const slideTranslate = (element, currentIndex) => {
    element.style.transform = `translateX(-${
      currentIndex * (100 / slides.length)
    }%)`;
  };

  // Sets the slides to right index and translation when a navigation dot is clicked
  const dotClick = (target, index) => {
    const buttons = btnContainer.current.querySelectorAll("button");
    slideTranslate(slideContainer.current, index);
    highlightButton(buttons, buttons[index]);
    setState({
      ...state,
      activeIndex: index,
    });
  };

  // Used by autoplay function to push to next slide
  const nextSlide = () => {
    const slides = slideContainer.current.querySelectorAll("article");
    const buttons = btnContainer.current.querySelectorAll("button");

    highlightButton(buttons, buttons[activeIndex]);
    slideTranslate(slideContainer.current, activeIndex);
    if (activeIndex === slides.length - 1) {
      return setState({
        ...state,
        activeIndex: 0,
      });
    }

    setState({
      ...state,
      activeIndex: activeIndex + 1,
    });
  };

  // Replace classes for highlighted button
  const highlightButton = (container, targetButton) => {
    container.forEach((element) => {
      element.classList.remove("slide-active");
      if (container && targetButton) {
        targetButton.classList.add("slide-active");
      }
    });
  };

  return (
    <div className="slide-show">
      <div ref={slideContainer} className="slide-container">
        {slides.map((element, index) => (
          <Article
            title={element.header}
            text={element.content.map((element, index) => (
              <p>{element}</p>
            ))}
          />
        ))}
      </div>
      <div ref={btnContainer} className="slide-buttons-container">
        {slides.map((element, index) => (
          <DotButton
            onClick={(event) => {
              dotClick(event.target, index);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default SlideShow;
