import React from "react";
import { Link } from "react-router-dom";

import { Logo } from "../../components";
import "./footer.css";

const Footer = ({ footerContent }) => {
  const currentDate = new Date();

  const currentYear = currentDate.getFullYear();

  return (
    <footer className="footer">
      <div className="footer-upper">
        <Logo />
        {footerContent.map((element, index) => {
          if (element.type === "address") {
            return (
              <ul>
                {element.content.map((element, index) => {
                  if (element.type === "text") {
                    return <li>{element.content}</li>;
                  } else
                    return (
                      <li>
                        <a href={element.link}>{element.title}</a>
                      </li>
                    );
                })}
              </ul>
            );
          } else {
            return (
              <ul>
                {element.content.map((element, index) => {
                  return (
                    <li>
                      <Link to={element.link}>{element.title}</Link>
                    </li>
                  );
                })}
              </ul>
            );
          }
        })}
      </div>
      <p className="copyright">©2021–{currentYear} all right reserved</p>
    </footer>
  );
};

export default Footer;
