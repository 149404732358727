import React from 'react';

import './feature.css'

const Feature = ({title, text}) => {
    return (
        <article className='feature'>
          <h2>{title}</h2>
          {text}
        </article>
    )
};

export default Feature;