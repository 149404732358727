import React from "react";

import { Article, Form } from "../../components";
import "./workUs.css";

const WorkUs = ({ contactContent }) => {
  return (
    <section id="workUs" className="workUs">
      <Article
        className={"back-white"}
        title={contactContent[1].header}
        text={contactContent[1].content.map((element) => (
          <p>{element}</p>
        ))}
      />
      <Form contactContent={contactContent} />
    </section>
  );
};

export default WorkUs;
