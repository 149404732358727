import React from "react";

import Feature from "../../components/feature/Feature";
import "./features.css";

const Features = ({ featuresContent }) => {
  return (
    <section className="features">
      <h2>{featuresContent[0].content}</h2>
      <div className="gridContainer">
        {featuresContent[1].content.map((element) => (
          <Feature
            title={element.header}
            text={element.content.map((element) => (
              <p>{element}</p>
            ))}
          />
        ))}
      </div>
    </section>
  );
};

export default Features;
