import "./image.css";

const Image = ({ source, alt }) => {
  const imgStyle = {
    display: "block",
    backgroundImage: `url(${source})`,
  };
  const altStyle = {
    position: "absolute",
    zIndex: -1,
  };
  return (
    <div style={imgStyle} className="image">
      <img style={altStyle} alt={alt} />
    </div>
  );
};

export default Image;
