import "./ipaBanner.css";

const IpaBanner = ({ imgArray }) => {
  return (
    <div className="ipa-banner">
      {imgArray.map((element) => {
        return element.address ? (
          <a target={"_blank"} rel="noreferrer" href={element.address}>
            <img alt="banner" src={element.imgSrc} />
          </a>
        ) : (
          <div className="ipa-container">
            <img alt={element.alt} src={element.imgSrc} />
          </div>
        );
      })}
    </div>
  );
};

export default IpaBanner;
