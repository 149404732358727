import { useState, useRef } from "react";
import "./langSelect.css";
import OutsideClickPlugin from "src/hooks/OutsideClickPlugin";
import { useMediaContext } from "src/utilities/mediaQuery";

const LangSelect = ({ languages, languageSelect }) => {
  const [menuTitle, setMenuTitle] = useState("eng");
  const [menuExpanded, setMenuExpanded] = useState(false);
  const containerRef = useRef(null);

  const screenMode = useMediaContext();

  // Triggered on list item click
  const handleLink = (lang) => {
    languageSelect(lang);
    setMenuTitle(lang);
    setMenuExpanded(false);
  };

  return (
    <div className="lang-container" ref={containerRef}>
      <div
        className="lang-title"
        onClick={() => setMenuExpanded(!menuExpanded)}
      >
        {menuTitle}
      </div>
      {menuExpanded && (
        <>
          <ul className="lang-menu">
            {languages.map((element) => (
              <li key={element.name} onClick={() => handleLink(element.short)}>
                {element.name}
              </li>
            ))}
          </ul>
          {screenMode === "mobile" && (
            <div
              onClick={() => setMenuExpanded(false)}
              className="burger-menu-bck"
            ></div>
          )}
        </>
      )}
      <OutsideClickPlugin
        callback={() => setMenuExpanded(false)}
        container={containerRef.current}
      />
    </div>
  );
};

export default LangSelect;
