import { useState } from "react";

import {
  euForEng,
  euForSrb,
  inoFundEng,
  inoFundSrb,
  progPodEng,
  progPodSrb,
  serbGovEng,
  serbGovSrb,
  znakUsaglasenosti,
  logoKatapult,
  contentSerbian,
  contentEnglish,
  nvidia,
} from "../assets";

const useLanguageSelect = () => {
  const [languageSelected, setLanguageSelected] = useState("eng");

  const languageSelect = (lang) => {
    setLanguageSelected(lang);
  };

  const activeLanguage = (lang) => {
    switch (lang) {
      case "srb":
        return contentSerbian;
      case "eng":
        return contentEnglish;
      default:
        return contentEnglish;
    }
  };

  const activeBanner = (lang) => {
    switch (lang) {
      case "srb":
        return [
          {
            address: "https://www.nvidia.com/en-eu/startups/venture-capital/",
            imgSrc: nvidia,
            alt: "nvidia",
          },
          { imgSrc: znakUsaglasenosti, alt: "ISO 13485" },
          { address: "https://www.mfin.gov.rs/sr", imgSrc: serbGovSrb },
          {
            address: "http://www.inovacionifond.rs/lat/program/katapult",
            imgSrc: logoKatapult,
          },
          {
            address:
              "https://www.euzatebe.rs/rs/projekti/programi-podrske-inovacijama-malih-i-srednjih-preduzeca",
            imgSrc: progPodSrb,
          },
          { address: "http://www.inovacionifond.rs/lat/", imgSrc: inoFundSrb },
          { address: "https://www.euzatebe.rs/", imgSrc: euForSrb },
        ];
      case "eng":
        return [
          {
            address: "https://www.nvidia.com/en-eu/startups/venture-capital/",
            imgSrc: nvidia,
            alt: "nvidia",
          },
          { imgSrc: znakUsaglasenosti, alt: "ISO 13485" },
          { address: "https://www.mfin.gov.rs/en", imgSrc: serbGovEng },
          {
            address: "http://www.inovacionifond.rs/en/programs/katapult",
            imgSrc: logoKatapult,
          },
          {
            address:
              "https://www.euzatebe.rs/en/projects/mini-and-matching-grants-programme",
            imgSrc: progPodEng,
          },
          { address: "http://www.inovacionifond.rs/en/", imgSrc: inoFundEng },
          { address: "https://www.euzatebe.rs/en/", imgSrc: euForEng },
        ];
      default:
        return [
          {
            address: "https://www.nvidia.com/en-eu/startups/venture-capital/",
            imgSrc: nvidia,
            alt: "nvidia",
          },
          { imgSrc: znakUsaglasenosti, alt: "ISO 13485" },
          { address: "https://www.mfin.gov.rs/en", imgSrc: serbGovEng },
          {
            address: "http://www.inovacionifond.rs/en/programs/katapult",
            imgSrc: logoKatapult,
          },
          {
            address:
              "https://www.euzatebe.rs/en/projects/mini-and-matching-grants-programme",
            imgSrc: progPodEng,
          },
          { address: "http://www.inovacionifond.rs/en/", imgSrc: inoFundEng },
          { address: "https://www.euzatebe.rs/en/", imgSrc: euForEng },
        ];
    }
  };

  return { languageSelect, languageSelected, activeLanguage, activeBanner };
};

export default useLanguageSelect;
