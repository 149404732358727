import React from "react";

import "./article.css";

const Article = ({ title, text, className }) => {
  return (
    <article className={`article ${className}`}>
      <h2>{title}</h2>
      {text}
    </article>
  );
};

export default Article;
