import { Link } from "react-router-dom";
import "./logo.css";
import { logoTelmed } from "../../assets";

const Logo = () => {
  return (
    <div className="logo-container">
      <Link to="/">
        <img src={logoTelmed} alt="telmed" />
        <h2>telmed</h2>
      </Link>
    </div>
  );
};

export default Logo;
