import { useEffect } from "react";

const OutsideClickPlugin = ({ callback, container }) => {
  useEffect(() => {
    const handleClick = (event) => {
      if (container && !container.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [callback, container]);

  return null;
};

export default OutsideClickPlugin;
