import { Link } from "react-router-dom";
import "./navbar.css";
import { useRef, useState } from "react";
import OutsideClickPlugin from "src/hooks/OutsideClickPlugin";

const Menu = ({ menuTitle, linkList }) => {
  const [menuExpanded, setMenuExpanded] = useState(false);
  const containerRef = useRef(null);

  const mapped = linkList.map((item) => {
    const { title, link } = item;
    return (
      <li key={title} onClick={() => setMenuExpanded(false)}>
        <Link to={`/${link}`}>{title}</Link>
      </li>
    );
  });

  const handleExpanded = () => {
    setMenuExpanded(!menuExpanded);
  };
  return (
    <li className="sub-container" ref={containerRef}>
      <span className="sub-title" onClick={() => handleExpanded()}>
        {menuTitle}
      </span>
      {menuExpanded && <ul className="sub-menu">{mapped}</ul>}
      <OutsideClickPlugin
        callback={() => setMenuExpanded(false)}
        container={containerRef.current}
      />
    </li>
  );
};

const Navbar = ({ linkList }) => {
  return (
    <nav className="nav-container">
      <ul className="nav-menu">
        {linkList.map((element, index) => {
          if (element.type === "link") {
            return (
              <li key={`${element}${index}`}>
                <Link to={`${element.link}`}>{element.title}</Link>
              </li>
            );
          } else {
            return (
              <>
                <Menu linkList={element.subMenu} menuTitle={element.title} />
              </>
            );
          }
        })}
      </ul>
    </nav>
  );
};

export default Navbar;
