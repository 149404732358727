import React, { useState, useReducer } from "react";

import "./form.css";

const formReducer = (state, evt) => {
  if (evt.reset) {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      message: "",
      applicantType: null,
      cv: [],
      other: [],
    };
  }
  return {
    ...state,
    [evt.name]: evt.value,
  };
};

const emptyForm = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  message: "",
  applicantType: null,
  cv: [],
  other: [],
};

const Form = ({ contactContent }) => {
  const [error, setError] = useState("");
  const [formData, setFormData] = useReducer(formReducer, emptyForm);
  const [submitting, setSubmitting] = useState(false);
  const [cvName, setCvName] = useState("");
  const [otherName, setOtherName] = useState("");
  const postUrl_ =
    "https://dev-server.telmed.dev/telmed-ticketing/api/websiteapi/applicants";
  //"http://localhost:8082/api/websiteapi/applicants";

  const setCv = (evt) => {
    const name = evt.target.files[0].name;
    const nameShort = `${name.slice(0, 8)}...${name.slice(-6)}`;
    setCvName(nameShort);
  };

  const setOther = (evt) => {
    const name = evt.target.files[0].name;
    const nameShort = `${name.slice(0, 8)}...${name.slice(-6)}`;
    setOtherName(nameShort);
  };

  const checkMailFormat = (email) => {
    console.log(/\S+@\S+\.\S+/.test(email));
    return /\S+@\S+\.\S+/.test(email);
  };

  async function fetchApplicant(url = "", data) {
    fetch(url, {
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data; boundary=",
        "Accept-Encoding": "gzip, deflate, br",
        "Access-Control-Allow-Origin": "*",
      },
      body: data,
    })
      .then((response) => response.json)
      .then(() => {
        setTimeout(() => {
          setSubmitting(false);
          setFormData({
            reset: true,
          });
          setCvName("");
          setOtherName("");
        }, 2000);
      })
      .catch((error) => {
        console.error("Error", error);
      });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (error === "") {
      setSubmitting(true);

      const applicant = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        message: formData.message,
        phoneNumber: formData.phoneNumber,
        applicantType: Number(formData.applicantType),
      };

      const structuredData = new FormData();

      if (formData.cv.length !== 0) {
        structuredData.append("cv", formData.cv);
      }
      if (formData.other.length !== 0) {
        structuredData.append("other", formData.other);
      }
      structuredData.append("applicant", JSON.stringify(applicant));

      fetchApplicant(postUrl_, structuredData);
    }
  };

  const handleChange = (evt) => {
    const isFile = evt.target.type === "file";

    setFormData({
      name: evt.target.name,
      value: isFile ? evt.target.files[0] : evt.target.value,
    });
  };

  return (
    <form
      className="form"
      id="contact-form"
      onSubmit={(evt) => {
        handleSubmit(evt);
      }}
    >
      {submitting === true ? (
        <div className="alert">{contactContent[4].content}</div>
      ) : (
        <></>
      )}
      <div className="field-section">
        <div className="input-container">
          <label>{contactContent[2].content[1]}</label>
          <input
            type="text"
            name="firstName"
            onChange={handleChange}
            value={formData.firstName}
            required
          />
        </div>
        <div className="input-container">
          <label>{contactContent[2].content[2]}</label>
          <input
            type="text"
            name="lastName"
            onChange={handleChange}
            value={formData.lastName}
            required
          />
        </div>
        <div className="input-container">
          {error !== "" ? (
            <label>
              {contactContent[2].content[3]}
              <span style={{ color: "red", fontStyle: "italic" }}>{error}</span>
            </label>
          ) : (
            <label>{contactContent[2].content[3]}</label>
          )}
          <input
            type="text"
            name="email"
            onChange={(evt) => {
              handleChange(evt);
              if (!checkMailFormat(evt.target.value)) {
                setError(contactContent[5].content);
              } else {
                setError("");
              }
            }}
            value={formData.email}
            required
          />
        </div>
        <div className="input-container">
          <label>{contactContent[2].content[4]}</label>
          <input
            type="text"
            name="phoneNumber"
            onChange={handleChange}
            value={formData.phoneNumber}
          />
        </div>
        <div className="doc-buttons">
          <div className="file-selector">
            <label htmlFor="cvSelect">{contactContent[2].content[6]}</label>
            <input
              id="cvSelect"
              type="file"
              name="cv"
              onChange={(evt) => {
                setCv(evt);
                handleChange(evt);
              }}
            />
            <div>{cvName}</div>
          </div>
          <div className="file-selector">
            <label htmlFor="otherSelect">{contactContent[2].content[7]}</label>
            <input
              id="otherSelect"
              type="file"
              name="other"
              onChange={(evt) => {
                setOther(evt);
                handleChange(evt);
              }}
            />
            <div>{otherName}</div>
          </div>
        </div>
      </div>
      <div className="comment">
        <label>{contactContent[2].content[5]}</label>
        <textarea
          rows={6}
          name="message"
          onChange={handleChange}
          value={formData.message}
        />
      </div>
      <fieldset className="radio-fieldset">
        <div className="radio-container">
          <input
            type="radio"
            id="doctor"
            name="applicantType"
            checked={formData.applicantType === "0"}
            onChange={handleChange}
            value="0"
            required
          />
          <label htmlFor="doctor">{contactContent[3].content[0]}</label>
        </div>
        <div className="radio-container">
          <input
            type="radio"
            id="clinic"
            name="applicantType"
            checked={formData.applicantType === "1"}
            onChange={handleChange}
            value="1"
          />
          <label htmlFor="clinic">{contactContent[3].content[1]}</label>
        </div>
        <div className="radio-container">
          <input
            type="radio"
            id="distributor"
            name="applicantType"
            checked={formData.applicantType === "2"}
            onChange={handleChange}
            value="2"
          />
          <label htmlFor="distributor">{contactContent[3].content[2]}</label>
        </div>
        <div className="radio-container">
          <input
            type="radio"
            id="other"
            name="applicantType"
            checked={formData.applicantType === "3"}
            onChange={handleChange}
            value="3"
          />
          <label htmlFor="other">{contactContent[3].content[3]}</label>
        </div>
      </fieldset>
      <input
        className="submit"
        type="submit"
        value={contactContent[2].content[8]}
      />
    </form>
  );
};

export default Form;
